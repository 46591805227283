import React, {useEffect, useState} from 'react';
import Layout from "../../components/layout/Layout";
import LoginContent from "../../components/login/LoginContent";
import {getConnected, loadScript} from "../../util/helper";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Login = ({}: any) => {
    const { t } = useTranslation()
    let connected = getConnected()
    const [user, setUser] = useState(connected ? connected : null)
    useEffect(() => {
        if(user){
            alert(`${user.firstName}, ${t('alreadyConnected')}`)
            window.location.replace("/")
        }
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
    })

    return (
        <>
            <Helmet>
                <title>OHADA | {t('signTitle')} </title>
            </Helmet>
            <Layout title={`OHADARB | ${t('login')}`}>
                <LoginContent/>
            </Layout>
        </>
    )
}

export default Login;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
