import React from 'react'
import {useTranslation} from "gatsby-plugin-react-i18next";

const LoginHeader = ({}: any) => {
    const { t, i18n } = useTranslation()
    return (
        <>
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-light-600 text-white"
                     data-image-src="/assets/img/bg18.png">
                <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h1 className="display-1 mb-3">{t('signIn')}</h1>
                            <nav className="d-inline-block" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">{t('home')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{t('signIn')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginHeader;
