import React, {useState, useEffect} from 'react';
import AuthService from "../../services/AuthService";
import {AUTH_TOKEN_NAME, AUTH_TOKEN_PREFIX, AUTH_TOKEN_SUFFIX, CONNECTED} from "../../services/FetchInterceptor";
import {useTranslation} from "gatsby-plugin-react-i18next";


const initialesValues = {username: "", password: ""};

const LoginForm = ({}: any) => {
    const [eye, setEye] = useState(false);
    const [formValues, setFormValues] = useState(initialesValues);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorSubmitted, setErrorSubmitted] = useState("");
    const showPwd = () => setEye(!eye);
    const { t, i18n } = useTranslation()
    const submit = () => {
        setLoading(true);
        const {username, password} = formValues;
        AuthService.login(username, password).then(
            (response) => {
                let token = response.headers['x-auth-token'];
                if (token) {
                    if (localStorage) {
                        const {id, firstName, lastName, username, roles, subscriptions, phoneNumber, countryCode} = response.data;
                        localStorage.setItem(AUTH_TOKEN_NAME, AUTH_TOKEN_PREFIX + token + AUTH_TOKEN_SUFFIX);
                        localStorage.setItem(CONNECTED, JSON.stringify({id, firstName, lastName, username, roles, subscriptions, phoneNumber, countryCode}));
                    }
                    const pathName = location.href;
                    if (pathName.includes('returnUrl')) {
                        const split = pathName.split('returnUrl=');
                        location.replace(`${split[1]}`);
                    } else
                        location.replace('/app/profile');
                }
            }
        ).catch((error: any) => {
            error.response ?
                error.response.status === 400 ? setErrorSubmitted(t('UserErrorMessage')) :
                    setErrorSubmitted(error.response.data.message) :
                setErrorSubmitted(t('serverErrorMessage'))
        }).finally(() => setLoading(false))
    };
    //input change handler
    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    //form submission handler
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    //form validation handler
    const validate = (values: any) => {
        let errors: any = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.username) {
            errors.username = t('cantBeEmpty')
        } else if (!regex.test(values.username)) {
            errors.username = t('emailErrorMessage')
        }

        if (!values.password) {
            errors.password = t('cantBeEmpty')
        } else if (values.password.length < 4) {
            errors.password = t('passwordErrorMessage');
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submit();
        }
    }, [formErrors]);
    return (
        <>
            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
                            <div className="card">
                                <div className="card-body p-11 text-center">
                                    <h2 className="mb-3 text-start">OHADARBITRATION | {t('signIn')}</h2>
                                    <p className="lead mb-6 text-start">{t('loginHeader')}</p>
                                    {errorSubmitted !== "" && (
                                        <div className="" style={{padding: 3, marginBottom: 5, borderRadius: 5, borderWidth: "1px", borderColor: "red", borderStyle: "solid"}}>
                                            <span className="error-submit-msg text-red shadow-lg font-monospace fs-md">{errorSubmitted}</span>
                                        </div>
                                    )}
                                    <form className="text-start mb-3" onSubmit={handleSubmit} noValidate>
                                        <div className="form-floating mb-4">
                                            <input
                                                name="username"
                                                type="email"
                                                className="form-control"
                                                placeholder="your-email@gmail.com"
                                                id="username"
                                                value={formValues.username}
                                                onChange={handleChange}
                                            />
                                            {formErrors.username && (<div className="error-msg text-danger">{formErrors.username}</div>)}
                                            <label htmlFor="username">{t('Email')}</label>
                                        </div>
                                        <div className="form-floating password-field mb-4">
                                            <input
                                                name="password"
                                                type={eye ? "text" : "password"}
                                                className="form-control"
                                                placeholder="Mot de passe"
                                                id="loginPassword"
                                                value={formValues.password}
                                                onChange={handleChange}
                                            />
                                            <span className="password-toggle" onClick={showPwd}>
                                                <i className={eye ? "uil uil-eye-slash" : "uil uil-eye"}></i>
                                            </span>
                                            {formErrors.password && (<div className="error-msg text-danger">{formErrors.password}</div>)}
                                            <label htmlFor="loginPassword">{t('password')}</label>
                                        </div>
                                        <button type="submit" className="btn btn-primary rounded-pill btn-login w-100 mb-2" disabled={loading}>
                                            {loading ? t('loading') : t('signIn')}
                                        </button>
                                    </form>
                                    <p className="mb-1"><a href="/app/forgotPassword" className="hover">{t('forgotPass')}</a></p>
                                    <p className="mb-0">{t('dontHaveAnAccount')} <a href="/app/register"
                                                                                  className="hover">{t('signUp')}</a></p>
                                    <div className="divider-icon my-4">or</div>
                                    <nav className="nav social justify-content-center text-center">
                                        <a href="#" className="btn btn-circle btn-sm btn-google"><i
                                            className="uil uil-google"></i></a>
                                        <a href="#" className="btn btn-circle btn-sm btn-facebook-f"><i
                                            className="uil uil-facebook-f"></i></a>
                                        <a href="#" className="btn btn-circle btn-sm btn-twitter"><i
                                            className="uil uil-twitter"></i></a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}





export default LoginForm
