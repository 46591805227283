import React from "react";
import LoginHeader from "./LoginHeader";
import LoginForm from "./LoginForm";

const LoginContent = ({}: any) => {
    return (
        <>
            <div className="content-wrapper">
                <LoginHeader />
                <LoginForm />
            </div>
        </>

    )
}
export default LoginContent;
